define("discourse/plugins/discourse-ai/discourse/components/ai-persona-list-editor", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/routing", "discourse/helpers/concat-class", "discourse/lib/text", "discourse-common/helpers/d-icon", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/components/ai-persona-editor", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _routing, _concatClass, _text, _dIcon, _discourseI18n, _aiPersonaEditor, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiPersonaListEditor = _exports.default = (_class = (_class2 = class AiPersonaListEditor extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_noPersonaText", _descriptor, this);
    }
    get noPersonaText() {
      if (this._noPersonaText === null) {
        const raw1 = _discourseI18n.default.t("discourse_ai.ai_persona.no_persona_selected");
        (0, _text.cook)(raw1).then(result1 => {
          this._noPersonaText = result1;
        });
      }
      return this._noPersonaText;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="ai-persona-list-editor__header">
        <h3>{{I18n.t "discourse_ai.ai_persona.title"}}</h3>
        {{#unless @currentPersona.isNew}}
          <LinkTo
            @route="adminPlugins.discourse-ai.ai-personas.new"
            class="btn btn-primary"
          >
            {{icon "plus"}}
            <span>{{I18n.t "discourse_ai.ai_persona.new"}}</span>
          </LinkTo>
        {{/unless}}
      </div>
      <div class="content-list ai-persona-list-editor">
        <ul>
          {{#each @personas as |persona|}}
            <li
              class={{concatClass
                (if persona.enabled "" "diabled")
                (if persona.priority "priority")
              }}
            >
              <LinkTo
                @route="adminPlugins.discourse-ai.ai-personas.show"
                current-when="true"
                @model={{persona}}
              >{{persona.name}}
              </LinkTo>
            </li>
          {{/each}}
        </ul>
      </div>
      <section class="ai-persona-list-editor__current content-body">
        {{#if @currentPersona}}
          <AiPersonaEditor @model={{@currentPersona}} @personas={{@personas}} />
        {{else}}
          <div class="ai-persona-list-editor__empty">
            {{this.noPersonaText}}
          </div>
        {{/if}}
      </section>
    
  */
  {
    "id": "ZGQwJcK9",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-persona-list-editor__header\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,0,[\"t\"]],[\"discourse_ai.ai_persona.title\"],null]],[13],[1,\"\\n\"],[41,[51,[30,1,[\"isNew\"]]],[[[1,\"        \"],[8,[32,1],[[24,0,\"btn btn-primary\"]],[[\"@route\"],[\"adminPlugins.discourse-ai.ai-personas.new\"]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[32,2],[\"plus\"],null]],[1,\"\\n          \"],[10,1],[12],[1,[28,[32,0,[\"t\"]],[\"discourse_ai.ai_persona.new\"],null]],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"content-list ai-persona-list-editor\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,2]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[15,0,[28,[32,3],[[52,[30,3,[\"enabled\"]],\"\",\"diabled\"],[52,[30,3,[\"priority\"]],\"priority\"]],null]],[12],[1,\"\\n            \"],[8,[32,1],[[24,\"current-when\",\"true\"]],[[\"@route\",\"@model\"],[\"adminPlugins.discourse-ai.ai-personas.show\",[30,3]]],[[\"default\"],[[[[1,[30,3,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"section\"],[14,0,\"ai-persona-list-editor__current content-body\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[8,[32,4],null,[[\"@model\",\"@personas\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"ai-persona-list-editor__empty\"],[12],[1,\"\\n          \"],[1,[30,0,[\"noPersonaText\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@currentPersona\",\"@personas\",\"persona\"],false,[\"unless\",\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-persona-list-editor.js",
    "scope": () => [_discourseI18n.default, _routing.LinkTo, _dIcon.default, _concatClass.default, _aiPersonaEditor.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_noPersonaText", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
});