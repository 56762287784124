define("discourse/plugins/discourse-ai/discourse/connectors/after-search-result-entry/search-result-decoration", ["exports", "@glimmer/component", "discourse-common/helpers/d-icon", "@ember/template-factory", "@ember/component"], function (_exports, _component, _dIcon, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class SearchResultDecoration extends _component.default {}
  _exports.default = SearchResultDecoration;
  _class = SearchResultDecoration;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="ai-result__icon">
        {{icon "discourse-sparkles"}}
      </div>
    
  */
  {
    "id": "Tyy7DFEj",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"ai-result__icon\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse-sparkles\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/after-search-result-entry/search-result-decoration.js",
    "scope": () => [_dIcon.default],
    "isStrictMode": true
  }), _class);
});