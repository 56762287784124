define("discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/semantic-search", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-toggle-switch", "discourse/controllers/full-page-search", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/lib/search", "discourse-common/helpers/d-icon", "I18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _modifier, _object, _didInsert, _service, _dToggleSwitch, _fullPageSearch, _ajax, _ajaxError, _pluginApi, _search, _dIcon, _I18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let SemanticSearch = _exports.default = (_class = (_class2 = class SemanticSearch extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "searching", _descriptor3, this);
      _initializerDefineProperty(this, "AIResults", _descriptor4, this);
      _initializerDefineProperty(this, "showingAIResults", _descriptor5, this);
      _defineProperty(this, "initialSearchTerm", this.args.outletArgs.search);
    }
    static shouldRender(_args1, _ref) {
      let {
        siteSettings: siteSettings1
      } = _ref;
      return siteSettings1.ai_embeddings_semantic_search_enabled;
    }
    get disableToggleSwitch() {
      if (this.searching || this.AIResults.length === 0) {
        return true;
      }
    }
    get searchStateText() {
      if (this.searching) {
        return _I18n.default.t("discourse_ai.embeddings.semantic_search_loading");
      }
      if (this.AIResults.length === 0) {
        return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.none");
      }
      if (this.AIResults.length > 0) {
        if (this.showingAIResults) {
          return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.toggle", {
            count: this.AIResults.length
          });
        } else {
          return _I18n.default.t("discourse_ai.embeddings.semantic_search_results.toggle_hidden", {
            count: this.AIResults.length
          });
        }
      }
    }
    get searchTerm() {
      if (this.initialSearchTerm !== this.args.outletArgs.search) {
        this.initialSearchTerm = undefined;
        this.resetAIResults();
      }
      return this.args.outletArgs.search;
    }
    get searchEnabled() {
      return this.args.outletArgs.type === _fullPageSearch.SEARCH_TYPE_DEFAULT && (0, _search.isValidSearchTerm)(this.searchTerm, this.siteSettings);
    }
    toggleAIResults() {
      if (this.showingAIResults) {
        this.args.outletArgs.addSearchResults([], "topic_id");
      } else {
        this.args.outletArgs.addSearchResults(this.AIResults, "topic_id");
      }
      this.showingAIResults = !this.showingAIResults;
    }
    resetAIResults() {
      this.AIResults = [];
      this.showingAIResults = false;
    }
    handleSearch() {
      if (!this.searchEnabled) {
        return;
      }
      if (this.initialSearchTerm) {
        return this.performHyDESearch();
      }
      (0, _pluginApi.withPluginApi)("1.15.0", api1 => {
        api1.onAppEvent("full-page-search:trigger-search", () => {
          return this.performHyDESearch();
        });
      });
    }
    performHyDESearch() {
      this.searching = true;
      this.resetAIResults();
      (0, _ajax.ajax)("/discourse-ai/embeddings/semantic-search", {
        data: {
          q: this.searchTerm
        }
      }).then(async results1 => {
        const model1 = (await (0, _search.translateResults)(results1)) || {};
        if (model1.posts?.length === 0) {
          this.searching = false;
          return;
        }
        model1.posts.forEach(post1 => {
          post1.generatedByAI = true;
        });
        this.AIResults = model1.posts;
      }).catch(_ajaxError.popupAjaxError).finally(() => this.searching = false);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.searchEnabled}}
        <div class="semantic-search__container search-results" role="region">
          <div class="semantic-search__results" {{didInsert this.handleSearch}}>
            <div
              class="semantic-search__searching
                {{if this.searching 'in-progress'}}"
            >
              <DToggleSwitch
                disabled={{this.disableToggleSwitch}}
                @state={{this.showingAIResults}}
                title="AI search results hidden"
                class="semantic-search__results-toggle"
                {{on "click" this.toggleAIResults}}
              />
  
              <div class="semantic-search__searching-text">
                {{icon "discourse-sparkles"}}
                {{this.searchStateText}}
              </div>
  
              {{#if this.searching}}
                <span class="semantic-search__indicator-wave">
                  <span class="semantic-search__indicator-dot">.</span>
                  <span class="semantic-search__indicator-dot">.</span>
                  <span class="semantic-search__indicator-dot">.</span>
                </span>
              {{/if}}
            </div>
          </div>
        </div>
      {{/if}}
    
  */
  {
    "id": "3cSLRgPA",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"searchEnabled\"]],[[[1,\"      \"],[10,0],[14,0,\"semantic-search__container search-results\"],[14,\"role\",\"region\"],[12],[1,\"\\n        \"],[11,0],[24,0,\"semantic-search__results\"],[4,[32,0],[[30,0,[\"handleSearch\"]]],null],[12],[1,\"\\n          \"],[10,0],[15,0,[29,[\"semantic-search__searching\\n              \",[52,[30,0,[\"searching\"]],\"in-progress\"]]]],[12],[1,\"\\n            \"],[8,[32,1],[[16,\"disabled\",[30,0,[\"disableToggleSwitch\"]]],[24,\"title\",\"AI search results hidden\"],[24,0,\"semantic-search__results-toggle\"],[4,[32,2],[\"click\",[30,0,[\"toggleAIResults\"]]],null]],[[\"@state\"],[[30,0,[\"showingAIResults\"]]]],null],[1,\"\\n\\n            \"],[10,0],[14,0,\"semantic-search__searching-text\"],[12],[1,\"\\n              \"],[1,[28,[32,3],[\"discourse-sparkles\"],null]],[1,\"\\n              \"],[1,[30,0,[\"searchStateText\"]]],[1,\"\\n            \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"searching\"]],[[[1,\"              \"],[10,1],[14,0,\"semantic-search__indicator-wave\"],[12],[1,\"\\n                \"],[10,1],[14,0,\"semantic-search__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n                \"],[10,1],[14,0,\"semantic-search__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n                \"],[10,1],[14,0,\"semantic-search__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/semantic-search.js",
    "scope": () => [_didInsert.default, _dToggleSwitch.default, _modifier.on, _dIcon.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searching", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "AIResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showingAIResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleAIResults", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAIResults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetAIResults", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetAIResults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearch"), _class.prototype)), _class);
});