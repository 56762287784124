define("discourse/plugins/discourse-ai/discourse/controllers/admin-dashboard-sentiment", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "admin/components/modal/custom-date-range", "admin/mixins/period-computation"], function (_exports, _controller, _object, _service, _getUrl, _decorators, _customDateRange, _periodComputation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminDashboardSentiment = _exports.default = (_dec = (0, _decorators.default)("startDate", "endDate"), (_class = class AdminDashboardSentiment extends _controller.default.extend(_periodComputation.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
    }
    filters(startDate, endDate) {
      return {
        startDate,
        endDate
      };
    }
    _reportsForPeriodURL(period) {
      return (0, _getUrl.default)(`/admin/dashboard/sentiment?period=${period}`);
    }
    setCustomDateRange(startDate, endDate) {
      this.setProperties({
        startDate,
        endDate
      });
    }
    openCustomDateRangeModal() {
      this.modal.show(_customDateRange.default, {
        model: {
          startDate: this.startDate,
          endDate: this.endDate,
          setCustomDateRange: this.setCustomDateRange
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "filters", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "filters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCustomDateRange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setCustomDateRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openCustomDateRangeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openCustomDateRangeModal"), _class.prototype)), _class));
});