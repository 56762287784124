define("discourse/plugins/discourse-ai/discourse/templates/admin-dashboard-sentiment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="sentiment section">
    <div class="period-section">
      <div class="section-title">
        <h2>
          {{i18n "discourse_ai.sentiments.dashboard.title"}}
        </h2>
        <span>
          <PeriodChooser
            @period={{this.period}}
            @action={{action "changePeriod"}}
            @content={{this.availablePeriods}}
            @fullDay={{false}}
          />
          <DButton
            @icon="cog"
            class="custom-date-range-button"
            @action={{this.openCustomDateRangeModal}}
            @title="admin.dashboard.custom_date_range"
          />
        </span>
      </div>
    </div>
  
    <div class="section-body">
      <div class="charts">
        <AdminReport
          @dataSourceName="overall_sentiment"
          @filters={{this.filters}}
          @showHeader={{true}}
        />
  
        <AdminReport
          @dataSourceName="post_emotion"
          @filters={{this.filters}}
          @showHeader={{true}}
        />
      </div>
    </div>
  </div>
  */
  {
    "id": "8J7MWjq4",
    "block": "[[[10,0],[14,0,\"sentiment section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"period-section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"section-title\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"discourse_ai.sentiments.dashboard.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@period\",\"@action\",\"@content\",\"@fullDay\"],[[30,0,[\"period\"]],[28,[37,2],[[30,0],\"changePeriod\"],null],[30,0,[\"availablePeriods\"]],false]],null],[1,\"\\n        \"],[8,[39,3],[[24,0,\"custom-date-range-button\"]],[[\"@icon\",\"@action\",\"@title\"],[\"cog\",[30,0,[\"openCustomDateRangeModal\"]],\"admin.dashboard.custom_date_range\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"section-body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"charts\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@dataSourceName\",\"@filters\",\"@showHeader\"],[\"overall_sentiment\",[30,0,[\"filters\"]],true]],null],[1,\"\\n\\n      \"],[8,[39,4],null,[[\"@dataSourceName\",\"@filters\",\"@showHeader\"],[\"post_emotion\",[30,0,[\"filters\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"period-chooser\",\"action\",\"d-button\",\"admin-report\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/admin-dashboard-sentiment.hbs",
    "isStrictMode": false
  });
});