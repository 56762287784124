define("discourse/plugins/discourse-ai/discourse/admin/models/ai-persona", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ATTRIBUTES = ["name", "description", "commands", "system_prompt", "allowed_group_ids", "enabled", "system", "priority"];
  class AiPersona extends _rest.default {
    updateProperties() {
      let attrs = this.getProperties(ATTRIBUTES);
      attrs.id = this.id;
      return attrs;
    }
    createProperties() {
      return this.getProperties(ATTRIBUTES);
    }
    workingCopy() {
      return AiPersona.create(this.createProperties());
    }
  }
  _exports.default = AiPersona;
});